import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Ensure base styles are imported
import './PhoneInputStyles.css'; // Adjust the path if necessary

const CustomPhoneInput = ( { isDisabled, ...props } ) => {
    return (
        <div className={ `scoped-phone-input ${ isDisabled ? 'input-disabled' : '' }` }>
            <PhoneInput
                { ...props }
                className={ `phone-input-custom ${ props.className || '' }` }
                defaultCountry="IN"
            />
        </div>
    );
};

export default CustomPhoneInput;
